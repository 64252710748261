"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Hindi (beta)
 */
exports.default = {
    layout: {
        default: [
            ["अ", "आ", "इ", "ई", "उ", "ऊ", "ऋ", "ऌ", "ए", "ऐ", "ओ", "औ"],
            ["अं", "अः", "क", "ख", "ग", "घ", "च", "छ", "ज", "झ", "ट", "ठ"],
            ["ड", "ढ", "ण", "त", "थ", "द", "ध", "न", "प", "फ", "ब", "भ"]
        ]
    }
};
