"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Serbian
 */
exports.default = {
    layout: {
        default: [
            ["љ", "њ", "е", "р", "т", "з", "у", "и", "о", "п", "ш"],
            ["а", "с", "д", "ф", "г", "х", "ј", "к", "л", "ч", "ћ"],
            ["џ", "ц", "в", "б", "н", "м", "ђ", "ж"],
        ]
    }
};
