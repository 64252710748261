"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Arabic
 */
exports.default = {
    layout: {
        default: [
            ["ج", "ح", "خ", "ه", "ع", "غ", "ف", "ق", "ث", "ص", "ض"],
            ["ة", "ك", "م", "ن", "ت", "ا", "ل", "ب", "ي", "س", "ش"],
            ["و", "ر", "ز", "د", "ذ", "ط", "ظ", "ء", "آ", "أ", "إ"]
        ]
    }
};
