"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Latvian
 */
exports.default = {
    layout: {
        default: [
            ["q", "w", "e", "ē", "r", "t", "y", "u", "ū", "i", "ī", "o", "p"],
            ["a", "ā", "s", "š", "d", "f", "g", "ģ", "h", "j", "k", "l", "ļ"],
            ["z", "ž", "x", "c", "č", "v", "b", "n", "ņ", "m"]
        ]
    }
};
