"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Russian
 */
exports.default = {
    layout: {
        default: [
            ["й", "ц", "у", "к", "е", "н", "г", "ш", "щ", "з", "х", "ъ"],
            ["ф", "ы", "в", "а", "п", "р", "о", "л", "д", "ж", "э"],
            ["я", "ч", "с", "м", "и", "т", "ь", "б", "ю"]
        ]
    }
};
