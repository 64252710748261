"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Japanese (Hiragana) (beta)
 */
exports.default = {
    layout: {
        default: [
            ["あ", "い", "う", "え", "お", "か", "き", "く", "け", "こ", "さ", "し"],
            ["す", "せ", "そ", "た", "ち", "つ", "て", "と", "な", "に", "ぬ", "ね"],
            ["の", "は", "ひ", "ふ", "へ", "ほ", "ま", "み", "む", "め", "も", "や"]
        ]
    }
};
