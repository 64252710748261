"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Hebrew
 */
exports.default = {
    layout: {
        default: [
            ["פ", "ו", "ט", "א", "ר", "ק"],
            ["ל", "ח", "י", "ע", "כ", "ג", "ד", "ש"],
            ["ת", "צ", "מ", "נ", "ה", "ב", "ס", "ז"]
        ]
    }
};
