"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Vietnamese (beta)
 */
exports.default = {
    layout: {
        default: [
            ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "đ", "à"],
            ["a", "s", "d", "f", "g", "h", "j", "k", "l", "á", "à", "ả"],
            ["z", "x", "c", "v", "b", "n", "m", "ó", "õ", "ô"]
        ]
    }
};
