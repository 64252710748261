"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Greek
 */
exports.default = {
    layout: {
        default: [
            ["ε", "ρ", "τ", "υ", "θ", "ι", "ο", "π"],
            ["α", "σ", "δ", "φ", "γ", "η", "ξ", "κ", "λ"],
            ["ζ", "χ", "ψ", "ω", "β", "ν", "μ"]
        ]
    }
};
