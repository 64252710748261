"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Turkish
 */
exports.default = {
    layout: {
        default: [
            ["q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "ğ", "ü"],
            ["a", "s", "d", "f", "g", "h", "j", "k", "l", "ş", "i"],
            ["z", "x", "c", "v", "b", "n", "m", "ö", "ç"],
        ]
    }
};
