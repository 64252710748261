"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Macedonian
 */
exports.default = {
    layout: {
        default: [
            ["љ", "њ", "е", "р", "т", "ѕ", "у", "и", "о", "п", "ш", "ѓ"],
            ["ж", "а", "с", "д", "ф", "г", "х", "ј", "к", "л", "ч", "ќ"],
            ["з", "џ", "ц", "в", "б", "н", "м"]
        ]
    }
};
