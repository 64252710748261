"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Georgian (beta)
 */
exports.default = {
    layout: {
        default: [
            ["ა", "ბ", "გ", "დ", "ე", "ვ", "ზ", "თ", "ი", "კ", "ლ", "მ"],
            ["ნ", "ო", "პ", "ჟ", "რ", "ს", "ტ", "უ", "ფ", "ქ", "ღ"],
            ["ყ", "შ", "ჩ", "ც", "ძ", "წ", "ჭ", "ხ", "ჯ", "ჰ"]
        ]
    }
};
