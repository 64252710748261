"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Traditional Chinese (Pinyin) (beta)
 */
exports.default = {
    layout: {
        default: [
            ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l"],
            ["m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x"],
            ["y", "z", "ü", "á", "è", "í", "ǒ", "ī", "ó", "ū", "í", "ǎ", "è"]
        ]
    }
};
