"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Ukrainian
 */
exports.default = {
    layout: {
        default: [
            ["й", "ц", "у", "к", "е", "н", "г", "ґ", "ш", "щ", "з", "х"],
            ["ф", "і", "ї", "в", "а", "п", "р", "о", "л", "д", "ж", "є"],
            ["я", "ч", "с", "м", "и", "т", "ь", "б", "ю"],
        ]
    }
};
