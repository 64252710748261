"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Bulgarian
 */
exports.default = {
    layout: {
        default: [
            ["я", "в", "е", "р", "т", "ъ", "у", "и", "о", "п", "ш", "щ"],
            ["а", "с", "д", "ф", "г", "х", "й", "к", "л", "ю"],
            ["ч", "з", "ь", "ц", "ж", "б", "н", "м"]
        ]
    }
};
