"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Thai (beta)
 */
exports.default = {
    layout: {
        default: [
            ["ก", "ข", "ค", "ฆ", "ง", "จ", "ฉ", "ช", "ซ", "ฌ", "ญ", "ฎ"],
            ["ฏ", "ฐ", "ฑ", "ฒ", "ณ", "ด", "ต", "ถ", "ท", "ธ", "น", "บ"],
            ["ป", "ผ", "ฝ", "พ", "ฟ", "ภ", "ม", "ย", "ร", "ล", "ว", "ศ"]
        ]
    }
};
