"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Croatian
 */
exports.default = {
    layout: {
        default: [
            ["e", "r", "t", "z", "u", "i", "o", "p", "š", "đ", "ǆ"],
            ["a", "s", "d", "f", "g", "h", "j", "k", "l", "č", "ć"],
            ["c", "v", "b", "n", "ǌ", "m", "ž", "ǉ"]
        ]
    }
};
