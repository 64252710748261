"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Bengali (beta)
 */
exports.default = {
    layout: {
        default: [
            ["১", "২", "৩", "৪", "৫", "৬", "৭", "৮", "৯", "০"],
            ["ঋ", "অ", "ই", "উ", "ঋ", "এ", "ও", "অ", "ক", "খ"],
            ["গ", "ঘ", "ঙ", "চ", "ছ", "জ", "ঝ", "ঞ", "ট", "ঠ", "ড"]
        ]
    }
};
