"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Korean (beta)
 */
exports.default = {
    layout: {
        default: [
            ["ㅏ", "ㅑ", "ㅓ", "ㅕ", "ㅗ", "ㅛ", "ㅜ", "ㅠ", "ㅡ", "ㅣ"],
            ["ㄱ", "ㄴ", "ㄷ", "ㄹ", "ㅁ", "ㅂ", "ㅅ", "ㅇ", "ㅈ", "ㅊ", "ㅋ", "ㅌ"],
            ["ㅍ", "ㅎ", "ㅃ", "ㅉ", "ㄲ", "ㄸ", "ㅆ", "ㅒ", "ㅖ", "ㅘ", "ㅙ", "ㅚ"]
        ]
    }
};
