"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Persian
 */
exports.default = {
    layout: {
        default: [
            ["پ", "چ", "ج", "ح", "خ", "ه", "ع", "غ", "ف", "ق", "ث", "ص", "ض"],
            ["گ", "ک", "م", "ن", "ت", "ا", "آ", "ل", "ب", "ی", "س", "ش"],
            ["و", "ئ", "د", "ذ", "ر", "ز", "ژ", "ط", "ظ"]
        ]
    }
};
