"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Layout: Urdu (beta)
 */
exports.default = {
    layout: {
        default: [
            ["ا", "ب", "پ", "ت", "ٹ", "ث", "ج", "چ", "ح", "خ", "د", "ڈ"],
            ["ذ", "ر", "ڑ", "ز", "ژ", "س", "ش", "ص", "ض", "ط", "ظ", "ع"],
            ["غ", "ف", "ق", "ک", "گ", "ل", "م", "ن", "ں", "و", "ہ", "ھ"]
        ]
    }
};
